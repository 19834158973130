<template>
  <v-col v-if="itemToPreview" cols="12" md="12" align-self="center">
    <v-col cols="12" sm="12" class="pa-0">
      <h2 v-resize-text="{minFontSize: '1px', maxFontSize: '20px'}"
      class="text-center">{{itemToPreview.name}}</h2>
    </v-col>
    <v-img :src="itemToPreview.imageUrl" alt="" contain height="300"> </v-img>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'product-info',
  computed: {
    ...mapGetters({
      itemToPreview: 'itemToPreview',
    }),
  },
  watch: {
    itemToPreview: {
      handler() {
      },
      deep: true,
    },
  },
  methods: {
    textChanged() {
      alert('adsad');
    },
  },
};
</script>
